import React from 'react';

import * as styles from './404.module.scss';

const Error404: React.FC = () => {
  return (
    <section className={styles.root}>
      <h2 className={styles.header}>Oops!</h2>
      <p className={styles.description}>Wygląda na to, że strona której szukasz nie istnieje.</p>
    </section>
  );
};

export default Error404;
